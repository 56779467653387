import React from "react";

interface Context {
  isLoggedIn: boolean;
  isAdmin: boolean;
  userId: string | null;
  token: string | null;
  tokenExpirationDate: Date | null;
  isNewsletterSubscriber: boolean;
  hasAlreadyPlacedOrder: boolean;
  cookieAgreement: boolean;
  hasDismissedNewsletterSubscription: boolean;
  visitCount: number;
  email: string | null;
  login: (
    uid: string,
    token: string,
    isNewsletterSubscriber: boolean,
    isAdmin: boolean,
    expirationDate?: Date,
    email?: string
  ) => void;
  logout: () => void;
  acceptCookieAgreement: () => void;
  subscribeToNewsletter: (email?: string) => void;
  dismissNewsletterSubscription: () => void;
  visit: () => void;
  order: () => void;
  checkAdmin: () => Promise<boolean>;
}

const AuthContext = React.createContext<Context>({
  isLoggedIn: false,
  userId: null,
  token: null,
  tokenExpirationDate: null,
  isNewsletterSubscriber: false,
  cookieAgreement: false,
  hasDismissedNewsletterSubscription: false,
  hasAlreadyPlacedOrder: false,
  visitCount: 0,
  isAdmin: false,
  email: null,
  login: () => {},
  logout: () => {},
  acceptCookieAgreement: () => {},
  subscribeToNewsletter: () => {},
  dismissNewsletterSubscription: () => {},
  visit: () => {},
  order: () => {},
  checkAdmin: () => Promise.resolve(false),
});

export default AuthContext;
