import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["fr"];
const availableLanguages = ["en", "fr"];

i18n.on("languageChanged", function (lng) {
  // if the language we switched to is the default language we need to remove the /en from URL
  const pathList1 = window.location.pathname.replace("/", "&").split("&");
  const pathList = pathList1[1].replace("/", "&").split("&");

  console.log(pathList);
  if (!availableLanguages.includes(pathList[0])) {
    //const pathNext = pathList[0]?pathList[0]:'';
    const newUrl = "/" + lng + window.location.pathname;
    window.location.replace(newUrl);
  } else {
    if (pathList[0] !== lng) {
      const pathNext = pathList[1] ? pathList[1] : "";
      const newUrl = "/" + lng + "/" + pathNext;
      window.location.replace(newUrl);
    }
  }

  /*console.log('chg')
  if (lng === fallbackLng[0]) {
    if (window.location.pathname.includes('/' + fallbackLng[0])) {
      const newUrl = window.location.pathname.replace('/' + fallbackLng[0], '')
      window.location.replace(newUrl)
    }
  }*/
});

i18n
  .use(Backend) // load translations using http (default
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    load: "languageOnly",
    //lng: navigator.language,
    //lng:'fr',
    backend: {
      /* translation file path */
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: fallbackLng,
    whitelist: availableLanguages,
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true, // options for language detection
    },
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["home", "gallery", "artwork", "productdetails", "products"],
    defaultNS: "home",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
