import React, { useState, useCallback, useEffect } from "react";

import AuthContext from "./auth-context";

let logoutTimer: NodeJS.Timeout;

const AuthContextProvider: React.FC = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).isLoggedIn !== undefined
        ? JSON.parse(localStorage.getItem("userData")!).isLoggedIn
        : false
      : false
  );
  const [userId, setUserId] = useState<string | null>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).userId !== undefined
        ? JSON.parse(localStorage.getItem("userData")!).userId
        : null
      : null
  );
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).token !== undefined
        ? JSON.parse(localStorage.getItem("userData")!).token
        : null
      : null
  );
  const [isAdmin, setIsAdmin] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).isAdmin !== undefined
        ? JSON.parse(localStorage.getItem("userData")!).isAdmin
        : false
      : false
  );
  const [visitCount, setVisitCount] = useState<number>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).visitCount !== undefined
        ? JSON.parse(localStorage.getItem("userData")!).visitCount
        : 0
      : 0
  );
  const [hasAlreadyPlacedOrder, setHasAlreadyPlacedOrder] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).hasAlreadyPlacedOrder !==
        undefined
        ? JSON.parse(localStorage.getItem("userData")!).hasAlreadyPlacedOrder
        : false
      : false
  );
  const [isNewsletterSubscriber, setIsNewsletterSubscriber] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).isNewsletterSubscriber !==
        undefined
        ? JSON.parse(localStorage.getItem("userData")!).isNewsletterSubscriber
        : false
      : false
  );
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date | null>(
    null
  );
  const [cookieAgreement, setCookieAgreement] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).cookieAgreement !==
        undefined
        ? JSON.parse(localStorage.getItem("userData")!).cookieAgreement
        : false
      : false
  );

  const [
    hasDismissedNewsletterSubscription,
    setHasDismissedNewsletterSubscription,
  ] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!)
        .hasDismissedNewsletterSubscription !== undefined
        ? JSON.parse(localStorage.getItem("userData")!)
          .hasDismissedNewsletterSubscription
        : false
      : false
  );

  const [email, setEmail] = useState<string | null>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).email !== undefined
        ? JSON.parse(localStorage.getItem("userData")!).email
        : null
      : null
  );

  const acceptCookieAgreement = () => {
    setCookieAgreement(true);
  };

  const subscribeToNewsletter = (email?: string) => {
    email && setEmail(email);
    setIsNewsletterSubscriber(true);
  };

  const dismissNewsletterSubscription = () => {
    setHasDismissedNewsletterSubscription(true);
  };

  const order = () => {
    setHasAlreadyPlacedOrder(true);
  };

  const visit = () => {
    setVisitCount((count) => count + 1);
  };

  const checkAdmin = async (): Promise<boolean> => {
    console.log(userId);
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        "api/users/isAdmin" +
        `?userId=${userId}`
    );
    const data = await response.json();
    return data.isAdmin ?? false;
  };

  useEffect(() => {
    localStorage.setItem(
      "userData",
      JSON.stringify({
        isLoggedIn,
        userId,
        token,
        isAdmin,
        expiration: (tokenExpirationDate || new Date()).toISOString(),
        isNewsletterSubscriber,
        hasDismissedNewsletterSubscription,
        hasAlreadyPlacedOrder,
        visitCount,
        email,
      })
    );
  }, [
    token,
    userId,
    hasDismissedNewsletterSubscription,
    isNewsletterSubscriber,
    hasAlreadyPlacedOrder,
    visitCount,
    isAdmin,
    email,
  ]);

  const login = useCallback(
    (
      uid: string,
      token: string,
      newsletterSubscriber: boolean,
      isAdmin: boolean,
      expirationDate?: Date,
      email?: string
    ) => {
      setToken(token);
      setUserId(uid);
      const tokenExpirationDate =
        expirationDate ||
        new Date(new Date().getTime() + 1000 * 60 * 60 * 2400);
      setIsAdmin(isAdmin);
      setTokenExpirationDate(tokenExpirationDate);
      setIsNewsletterSubscriber(newsletterSubscriber);
      email && setEmail(email);
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setIsAdmin(false);
    setEmail(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem("userData") != null
        ? localStorage.getItem("userData")!
        : "{}"
    );

    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.isNewsletterSubscriber,
        storedData.isAdmin,
        new Date(storedData.expiration),
        storedData.email
      );
    }
  }, [login]);

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userId,
        token,
        tokenExpirationDate,
        cookieAgreement,
        isNewsletterSubscriber,
        hasDismissedNewsletterSubscription,
        hasAlreadyPlacedOrder,
        visitCount,
        isAdmin,
        login,
        logout,
        acceptCookieAgreement,
        subscribeToNewsletter,
        dismissNewsletterSubscription,
        visit,
        order,
        checkAdmin,
        email
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
