import React from "react";

interface Context {
  priceList: Array<object> | null;
  galleryCollectionPrice: Number;
  galleryRegularPrice: Number;
  finalRegularPrice: Number;
  finalCollectionPrice: Number;
  shippingPrice: Number;
  expressPrice: Number;
  populatePriceList: (price_list: Array<any>) => void;
  getPricePerDim: (dim_cm: Number) => any;
  getFinalPricing: (
    dim: Number,
    art_type: string,
    collection_moulding: boolean,
    mount: string,
    mat_shape: string,
    mat_top?: number,
    mat_bottom?: number,
    mat_right?: number,
    mat_left?: number
  ) => any;
}

const PriceContext = React.createContext<Context>({
  priceList: null,
  galleryCollectionPrice: 0,
  galleryRegularPrice: 0,
  finalRegularPrice: 0,
  finalCollectionPrice: 0,
  shippingPrice: 6,
  expressPrice: 15,
  populatePriceList: () => {},
  getPricePerDim: () => {},
  getFinalPricing: () => {},
});

export default PriceContext;
