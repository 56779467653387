import React, { useContext } from "react";
import {
  IonToast, isPlatform,
} from "@ionic/react";

import AuthContext from "../../../context/auth-context";
import { useTranslation } from "react-i18next";

const CookieModal: React.FC<{
}> = () => {

  const authCtx = useContext(AuthContext);
  const { t } = useTranslation("layout");
  
  return (
    <IonToast
    isOpen={!authCtx.cookieAgreement}
    message={t('cookie.message')}
    onDidDismiss={(e) => authCtx.acceptCookieAgreement()}
    keyboardClose={true}
    mode='ios'
    //duration={10000}
    position={isPlatform('mobile')?'top':'bottom'}
    buttons={[
      {
        text: "ok",
        role: "cancel",
        side: "end",
        handler: () => {
          authCtx.acceptCookieAgreement();
        },
      },
    ]}
  />
  );
};

export default CookieModal;
