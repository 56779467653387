import React, { useContext, useEffect, useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  IonButtons,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonBadge,
} from "@ionic/react";
import {
  logoFacebook,
  logoInstagram,
  logoPinterest,
  basketOutline,
  gift,
  arrowForward,
  chevronUp,
  chevronDown,
  ellipse,
} from "ionicons/icons";
import AuthContext from "../../../context/auth-context";
import cContext from "../../../context/cart-context";
import { useHttpClient } from "../../../hooks/http-hook";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const SideDrawer: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(cContext);
  const { t, i18n } = useTranslation("layout");

  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
  };

  const [loadedItems, setLoadedItems] = useState<Array<any>>([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [displayEshopSubMenu, setDisplayEshopSubMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [route, setRoute] = useState(location.pathname);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        let article_ids = [];
        if (cartCtx && cartCtx.cartItems) {
          for (var j = 0; j < cartCtx.cartItems?.length; j++) {
            article_ids.push(cartCtx.cartItems[j].article_id);
          }
          const data = JSON.stringify({
            article_ids: article_ids,
          });
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "api/shop/order/getCart",
            "POST",
            data,
            {
              "Content-Type": "application/json",
            }
          );
          console.log(responseData.items);
          setLoadedItems(responseData.items);
          //console.log(responseData.products);
        }
      } catch (err) {}
    };
    fetchCart();
  }, [sendRequest, cartCtx.cartItems]);

  useEffect(() => {
    setRoute(location.pathname);
  }, [location.pathname]);

  const myOrderHandler = () => {
    history.push("/" + i18n.language + "/account/orders");
  };

  return (
    <IonMenu contentId="main" swipeGesture={false} side="end">
      <IonHeader color="light">
        <IonToolbar className="ion-text-center">
          <div className="ion-text-center">
            <IonButton
              routerLink={"/" + i18n.language + "/"}
              routerDirection="back"
              mode="ios"
              fill="clear"
            >
              <img
                style={{ maxHeight: "40px" }}
                src="assets/img/logo_black.png"
              />
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem
            lines="none"
            // routerDirection="forward"
            routerLink={"#"}
            onClick={() => setDisplayEshopSubMenu(!displayEshopSubMenu)}
            detail={false}
          >
            <IonText>
              <h3>{t("side.eshop")}</h3>
            </IonText>
            <IonIcon
              slot="end"
              icon={displayEshopSubMenu ? chevronUp : chevronDown}
              onClick={() => setDisplayEshopSubMenu(!displayEshopSubMenu)}
            />
          </IonItem>

          <IonMenuToggle>
            {displayEshopSubMenu && (
              <React.Fragment>
                <IonItem
                  lines="none"
                  detail={false}
                  routerDirection="forward"
                  routerLink={"/" + i18n.language + "/frame-physical"}
                >
                  <IonIcon
                    icon={arrowForward}
                    size="small"
                    style={{ marginRight: "5px" }}
                  />
                  <p
                    style={
                      route.startsWith("/" + i18n.language + "/frame-physical")
                        ? { borderBottom: "3px solid #ffae8a" }
                        : {}
                    }
                    color="medium"
                  >
                    {t("sub.eshop.title.1")}
                  </p>
                </IonItem>
                <IonItem
                  lines="none"
                  detail={false}
                  routerDirection="forward"
                  routerLink={
                    "/" +
                    i18n.language +
                    "/build-digital?art_type=digital_photo"
                  }
                >
                  <IonIcon
                    icon={arrowForward}
                    size="small"
                    style={{ marginRight: "5px" }}
                  />
                  <p
                    style={
                      route.startsWith("/" + i18n.language + "/build-digital")
                        ? { borderBottom: "3px solid #ffae8a" }
                        : {}
                    }
                    color="medium"
                  >
                    {t("sub.eshop.title.2")}
                  </p>
                </IonItem>
                <IonItem
                  lines="none"
                  detail={false}
                  routerDirection="back"
                  routerLink={"/" + i18n.language + "/pelemele"}
                >
                  <IonIcon
                    icon={arrowForward}
                    size="small"
                    style={{ marginRight: "5px" }}
                  />
                  <p
                    style={
                      route.startsWith("/" + i18n.language + "/pelemele")
                        ? { borderBottom: "3px solid #ffae8a" }
                        : {}
                    }
                    color="medium"
                  >
                    {t("sub.eshop.title.3")}
                  </p>
                </IonItem>
                <IonItem
                  lines="none"
                  detail={false}
                  routerDirection="back"
                  routerLink={"/" + i18n.language + "/gallery-walls"}
                >
                  <IonIcon
                    icon={arrowForward}
                    size="small"
                    style={{ marginRight: "5px" }}
                  />
                  <p
                    style={
                      route.startsWith("/" + i18n.language + "/gallery-walls")
                        ? { borderBottom: "3px solid #ffae8a" }
                        : {}
                    }
                    color=""
                  >
                    {t("sub.eshop.title.4")}
                  </p>
                </IonItem>
              </React.Fragment>
            )}
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/how-it-works"}
              detail={false}
            >
              <IonText>
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/how-it-works")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.howitworks")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="back"
              routerLink={"/" + i18n.language + "/gifts"}
              detail={false}
            >
              <IonText color="danger" className="y-align">
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/gifts")
                      ? { borderBottom: "3px solid #eb445a" }
                      : {}
                  }
                >
                  {t("side.to.offer")}
                </h3>
                <IonIcon icon={gift} style={{ marginLeft: "5px" }} />
              </IonText>
            </IonItem>
            <IonItem
              lines="full"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/gallery"}
              detail={false}
            >
              <IonText>
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/gallery")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.gallery")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              detail={false}
              lines="none"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/guide"}
            >
              <IonText>
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/guide")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.guide")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/know-how"}
              detail={false}
            >
              <IonText>
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/know-how")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.knowhow")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              detail={false}
              lines="none"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/our-mission"}
            >
              <IonText>
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/our-mission")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.ourmission")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="full"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/pricing"}
              detail={false}
            >
              <IonText
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/pricing")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.pricing")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem lines="none">
              <IonButton
                routerLink="cart"
                routerDirection="forward"
                mode="ios"
                fill="clear"
                color="dark"
                slot="start"
              >
                <IonIcon size="large" icon={basketOutline} />
                <IonText>
                  {" "}
                  <h3
                    style={
                      route.startsWith("/" + i18n.language + "/cart")
                        ? { borderBottom: "3px solid #ffae8a" }
                        : {}
                    }
                  >
                    &nbsp; &nbsp; &nbsp;{t("side.cart")}
                  </h3>
                </IonText>
              </IonButton>
              {cartCtx.cartItems != null && cartCtx.cartItems.length > 0 && (
                <IonBadge slot="end" color="warning">
                  {cartCtx.cartItems.length}
                </IonBadge>
              )}
            </IonItem>
            <IonItem
              lines="full"
              routerDirection="forward"
              routerLink={
                authCtx.isLoggedIn
                  ? "/" + i18n.language + "/account/orders"
                  : "/" + i18n.language + "/account/login"
              }
              detail={false}
            >
              <IonText color="dark">
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/account/")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.account")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/services"}
              detail={false}
            >
              <IonText color="dark">
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/services")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.services")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink={"/" + i18n.language + "/faq"}
              detail={false}
            >
              <IonText color="dark">
                <h3
                  style={
                    route.startsWith("/" + i18n.language + "/faq")
                      ? { borderBottom: "3px solid #ffae8a" }
                      : {}
                  }
                >
                  {t("side.faq")}
                </h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              href="mailto:hello@lamafactory.com"
              detail={false}
            >
              <IonText color="dark">
                <h3>{t("side.contact")}</h3>
              </IonText>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle key="social">
            <IonItem lines="none">
              <IonButtons slot="start">
                {/* <IonButton
                  href="https://www.facebook.com/lamafactory/"
                  fill="clear"
                  color="dark"
                >
                  <IonIcon slot="icon-only" icon={logoFacebook} />
                </IonButton>
                <IonButton
                  fill="clear"
                  color="dark"
                  href="https://www.instagram.com/lamafactory_com/"
                >
                  <IonIcon slot="icon-only" icon={logoInstagram} />
                </IonButton> */}
                <IonButton
                  href="https://www.pinterest.fr/lama_factory/"
                  fill="clear"
                  color="dark"
                >
                  <IonIcon slot="icon-only" icon={logoPinterest} />
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle key="language" className="ion-padding-bottom">
            <IonItem lines="none">
              <IonSegment
                mode="ios"
                value={i18n.language}
                color="dark"
                onIonChange={(e) => changeLanguage(e.detail.value)}
              >
                <IonSegmentButton value="fr">
                  <IonLabel>fr</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="en">
                  <IonLabel>en</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default SideDrawer;
