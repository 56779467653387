import React, { createRef, useEffect } from "react";
import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, isPlatform, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { IntercomProvider } from "react-use-intercom";
import TagManager from "react-gtm-module";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/home.css";
//import "./theme/build-size.css";
import "./theme/variables.css";
import "./theme/theme.css";
import "./theme/home_style_v2.css";

/*Context & Hooks loading */
import FContextProvider from "./context/fContextProvider";
import AuthContextProvider from "./context/authContextProvider";
import CContextProvider from "./context/cartContextProvider";
import PriceContextProvider from "./context/priceContextProvider";
import { useAuth } from "./hooks/auth-hook";

/* Shop app pages*/
import SideDrawer from "./components/shared/nav/SideDrawer";
import CookieModal from "./components/shared/nav/CookieModal";
import NewsletterSubscriptionModal from "./components/shop/others/NewsletterSubscriptionContainer";
const BillingAddress = React.lazy(
  () => import("./pages/order/billing-address")
);
const Gift = React.lazy(() => import("./pages/shop/products/gift"));
const GiftCard = React.lazy(() => import("./pages/shop/products/gift-card"));
const NewsletterUnsubscribe = React.lazy(
  () => import("./pages/account/newsletter-unsubscribe")
);
const AdminCreateFrame = React.lazy(() => import("./pages/admin/create-frame"));
const AdminCustomizeFrame = React.lazy(
  () => import("./pages/admin/customize-frame")
);
const AdminReviews = React.lazy(() => import("./pages/admin/reviews/reviews"));
const ProductDetailsStandard = React.lazy(
  () => import("./pages/shop/product-details-standard")
);
const SmallLama = React.lazy(() => import("./pages/shop/products/small-lama"));
const Pelemele = React.lazy(() => import("./pages/shop/products/pelemele"));
const GalleryWall = React.lazy(
  () => import("./pages/shop/products/gallery-wall")
);
const ProductDetailsPelemele = React.lazy(
  () => import("./pages/shop/products/product-details-pelemele")
);
const ProductDetailsGalleryWall = React.lazy(
  () => import("./pages/shop/products/product-details-gallery-wall")
);

const GalleryWallConfigurator = React.lazy(
  () => import("./pages/shop/products/gallery-wall-configurator")
);
const tagManagerArgs = {
  gtmId: "GTM-TVTFG8T",
};
TagManager.initialize(tagManagerArgs);

const Home = React.lazy(() => import("./pages/shop/home"));
const FrameDigital = React.lazy(
  () => import("./pages/shop/funnel/frame-digital")
);
const BuildUpload = React.lazy(
  () => import("./pages/shop/funnel/build-upload")
);
const BuildSize = React.lazy(() => import("./pages/shop/funnel/build-size"));
const Gallery = React.lazy(() => import("./pages/shop/gallery"));
const ProductDetailsTech2 = React.lazy(
  () => import("./pages/shop/product-details")
);
const ProductDetailsArticle = React.lazy(
  () => import("./pages/shop/product-details-article")
);
const Cart = React.lazy(() => import("./pages/order/cart"));
const Wishlist = React.lazy(() => import("./pages/order/wishlist"));
const FramePhysical = React.lazy(
  () => import("./pages/shop/funnel/frame-physical")
);
const Frame = React.lazy(() => import("./pages/shop/funnel/frame"));
const FrameCanvas = React.lazy(
  () => import("./pages/shop/funnel/frame-canvas")
);
const FrameTextil = React.lazy(
  () => import("./pages/shop/funnel/frame-textil")
);
const FrameObject = React.lazy(
  () => import("./pages/shop/funnel/frame-object")
);
const FramePhoto = React.lazy(() => import("./pages/shop/funnel/frame-photo"));

const UserInfo = React.lazy(() => import("./pages/order/user-info"));
const UserSignup = React.lazy(() => import("./pages/order/user-signup"));
const UserLogin = React.lazy(() => import("./pages/order/user-login"));
const ShippingAddress = React.lazy(
  () => import("./pages/order/shipping-address")
);
const CollectionMethod = React.lazy(
  () => import("./pages/order/collection-method")
);
const ShippingMethod = React.lazy(
  () => import("./pages/order/shipping-method")
);
const PaymentMethod = React.lazy(() => import("./pages/order/payment"));

const Trade = React.lazy(() => import("./pages/shop/info/trade"));
const FAQ = React.lazy(() => import("./pages/shop/info/faq"));
const Legal = React.lazy(() => import("./pages/shop/info/legal"));
const Orders = React.lazy(() => import("./pages/account/orders"));

const Login = React.lazy(() => import("./pages/account/login"));
const Register = React.lazy(() => import("./pages/account/register"));
const AuthRedirection = React.lazy(
  () => import("./pages/account/auth-redirection")
);

const Reset = React.lazy(() => import("./pages/account/reset"));
const HowItWorks = React.lazy(() => import("./pages/shop/info/how-it-works"));
const HowItWorksDigital = React.lazy(
  () => import("./pages/shop/info/how-it-works-digital")
);
const HowItWorksPhysical = React.lazy(
  () => import("./pages/shop/info/how-it-works-physical")
);
const OurMission = React.lazy(() => import("./pages/shop/info/our-mission"));
const Services = React.lazy(() => import("./pages/shop/info/services"));
const KnowHow = React.lazy(() => import("./pages/shop/info/know-how"));
const Guide = React.lazy(() => import("./pages/shop/info/guide"));
const Pricing = React.lazy(() => import("./pages/shop/info/pricing"));

const MadeToMeasurePhotoFrame = React.lazy(
  () => import("./pages/shop/seo/made-to-measure-photo-frame")
);
const PhotoFrame = React.lazy(() => import("./pages/shop/seo/photo-frame"));
const CustomPhotoFrame = React.lazy(
  () => import("./pages/shop/seo/custom-photo-frame")
);
const PeleMeleFrame = React.lazy(
  () => import("./pages/shop/seo/pele-mele-frame")
);
const MadeToMeasure = React.lazy(
  () => import("./pages/shop/seo/made-to-measure")
);
const CaisseAmericaine = React.lazy(
  () => import("./pages/shop/seo/caisse-americaine")
);
const Reviews = React.lazy(() => import("./pages/shop/reviews/reviews"));
const WoodFrame = React.lazy(() => import("./pages/shop/seo/wood-frame"));
const GoldenFrame = React.lazy(() => import("./pages/shop/seo/golden-frame"));
const TableauFrame = React.lazy(() => import("./pages/shop/seo/tableau-frame"));
const JerseyFrame = React.lazy(() => import("./pages/shop/seo/jersey-frame"));
const FramerParis = React.lazy(() => import("./pages/shop/seo/framer-paris"));
const FramerLyon = React.lazy(() => import("./pages/shop/seo/framer-lyon"));
const FramerBordeaux = React.lazy(
  () => import("./pages/shop/seo/framer-bordeaux")
);
const FramerMarseille = React.lazy(
  () => import("./pages/shop/seo/framer-marseille")
);
const FramerNantes = React.lazy(() => import("./pages/shop/seo/framer-nantes"));

const Feedback = React.lazy(() => import("./pages/order/feedback"));

const INTERCOM_APP_ID = "";

const App: React.FC = () => {
  const baseRouteUrl = "/:locale(fr|en)?";
  const { token } = useAuth();
  let routes;

  if (token) {
    routes = (
      <IonRouterOutlet id="main" animated={false}>
        <Route
          path={baseRouteUrl + "/encadrement-sur-mesure/encadreur-:city"}
          exact
        >
          <MadeToMeasurePhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo-sur-mesure"} exact>
          <MadeToMeasurePhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo"} exact>
          <PhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo-pele-mele"} exact>
          <PeleMeleFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo-personnalise"} exact>
          <CustomPhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-sur-mesure"} exact>
          <MadeToMeasure />
        </Route>
        <Route path={baseRouteUrl + "/caisse-americaine"} exact>
          <CaisseAmericaine />
        </Route>
        <Route path={baseRouteUrl + "/cadre-bois"} exact>
          <WoodFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-dore"} exact>
          <GoldenFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-tableau"} exact>
          <TableauFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-maillot"} exact>
          <JerseyFrame />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-paris"} exact>
          <FramerParis />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-lyon"} exact>
          <FramerLyon />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-bordeaux"} exact>
          <FramerBordeaux />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-marseille"} exact>
          <FramerMarseille />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-nantes"} exact>
          <FramerNantes />
        </Route>
        <Route path={baseRouteUrl + "/frame-physical"} exact>
          <FramePhysical />
        </Route>
        <Route path={baseRouteUrl + "/frame-digital"} exact>
          <FrameDigital />
        </Route>
        <Route path={baseRouteUrl + "/frame"} exact>
          <Frame />
        </Route>
        <Route path={baseRouteUrl + "/frame-canvas"} exact>
          <FrameCanvas />
        </Route>
        <Route path={baseRouteUrl + "/frame-textil"} exact>
          <FrameTextil />
        </Route>
        <Route path={baseRouteUrl + "/frame-object"} exact>
          <FrameObject />
        </Route>
        <Route path={baseRouteUrl + "/frame-photo"} exact>
          <FramePhoto />
        </Route>
        <Route path={baseRouteUrl + "/legal"} exact>
          <Legal />
        </Route>
        <Route path={baseRouteUrl + "/trade"} exact>
          <Trade />
        </Route>
        <Route path={baseRouteUrl + "/faq"} exact>
          <FAQ />
        </Route>
        <Route path={baseRouteUrl + "/how-it-works"} exact>
          <HowItWorks />
        </Route>
        <Route path={baseRouteUrl + "/how-it-works-digital"} exact>
          <HowItWorksDigital />
        </Route>
        <Route path={baseRouteUrl + "/how-it-works-physical"} exact>
          <HowItWorksPhysical />
        </Route>
        <Route path={baseRouteUrl + "/our-mission"} exact>
          <OurMission />
        </Route>
        <Route path={baseRouteUrl + "/pricing"} exact>
          <Pricing />
        </Route>
        <Route path={baseRouteUrl + "/services"} exact>
          <Services />
        </Route>
        <Route path={baseRouteUrl + "/guide"} exact>
          <Guide />
        </Route>
        <Route path={baseRouteUrl + "/know-how"} exact>
          <KnowHow />
        </Route>
        <Route path={baseRouteUrl + "/build-digital"} exact>
          <BuildUpload />
        </Route>
        <Route path={baseRouteUrl + "/build-size"} exact>
          <BuildSize />
        </Route>
        <Route path={baseRouteUrl + "/gallery"} exact>
          <Gallery />
        </Route>
        <Route
          path="/shop/Parisian Cars"
          exact
          component={() => {
            window.location.href = "https://camillegabarra.lamafactory.com/";
            return null;
          }}
        />
        <Route
          path="/shop/Parisian Cars/:article"
          exact
          component={(props: { match: { params: { article: string } } }) => {
            window.location.href =
              "https://camillegabarra.lamafactory.com/shop/Parisian Cars/" +
              props.match.params.article;
            return null;
          }}
        />
        <Route path={baseRouteUrl + "/order/:orderId/collectionMethod"}>
          <CollectionMethod />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/login"}>
          <UserLogin />
        </Route>
        <Route path={baseRouteUrl + "/authRedirection"}>
          <AuthRedirection />
        </Route>
        <Route path={baseRouteUrl + "/newsletter-unsubscribe/:token"}>
          <NewsletterUnsubscribe />
        </Route>
        <Route exact path={baseRouteUrl + "/product/:productName"}>
          <ProductDetailsTech2 />
        </Route>
        <Route
          exact
          path={baseRouteUrl + "/product/pelemele/:productName/:mouldingName"}
        >
          <ProductDetailsPelemele />
        </Route>
        <Route
          exact
          path={
            baseRouteUrl + "/product/gallery-wall/:productName/:mouldingName"
          }
        >
          <ProductDetailsGalleryWall />
        </Route>
        <Route
          exact
          path={baseRouteUrl + "/gallery-wall/:productName/:mouldingName"}
        >
          <GalleryWallConfigurator />
        </Route>
        <Route
          exact
          path={baseRouteUrl + "/product/:productName/:mouldingName"}
        >
          <ProductDetailsStandard />
        </Route>
        <Route exact path={baseRouteUrl + "/gifts"}>
          <Gift />
        </Route>

        <Route exact path={baseRouteUrl + "/gift-card"}>
          <GiftCard />
        </Route>
        <Route exact path={baseRouteUrl + "/petit-lama"}>
          <SmallLama />
        </Route>
        <Route exact path={baseRouteUrl + "/pelemele"}>
          <Pelemele />
        </Route>
        <Route exact path={baseRouteUrl + "/gallery-walls"}>
          <GalleryWall />
        </Route>

        <Route exact path={baseRouteUrl + "/article/:id"}>
          <ProductDetailsArticle />
        </Route>
        <Route path={baseRouteUrl + "/cart"}>
          <Cart />
        </Route>
        <Route path={baseRouteUrl + "/wishlist/:wishlistId"}>
          <Wishlist />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/info"}>
          <UserInfo />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/shippingAddress"}>
          <ShippingAddress />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/billingAddress"}>
          <BillingAddress />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/signup"}>
          <UserSignup />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/shippingMethod"}>
          <ShippingMethod />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/payment"}>
          <PaymentMethod />
        </Route>
        <Route path={baseRouteUrl + "/account/reset/:token"}>
          <Reset />
        </Route>
        <Route path={baseRouteUrl + "/account/orders"}>
          <Orders />
        </Route>
        <Route path={baseRouteUrl + "/account/login"}>
          <Login />
        </Route>
        <Route path={baseRouteUrl + "/admin/create-frame"}>
          <AdminCreateFrame />
        </Route>
        <Route path={baseRouteUrl + "/admin/customize-frame/:productName"}>
          <AdminCustomizeFrame />
        </Route>
        <Route path={baseRouteUrl + "/admin/reviews"}>
          <AdminReviews />
        </Route>
        <Route path={baseRouteUrl + "/register"}>
          <Register />
        </Route>
        <Route path={baseRouteUrl + "/feedback/:orderId"}>
          <Feedback />
        </Route>
        <Route path={baseRouteUrl + "/reviews"}>
          <Reviews />
        </Route>
        <Route path={baseRouteUrl + "/"}>
          <Home />
        </Route>
      </IonRouterOutlet>
    );
  } else {
    routes = (
      <IonRouterOutlet id="main" mode="ios" animated={false}>
        <Route
          path={baseRouteUrl + "/encadrement-sur-mesure/encadreur-:city"}
          exact
        >
          <MadeToMeasurePhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/frame-physical"} exact>
          <FramePhysical />
        </Route>
        <Route path={baseRouteUrl + "/frame-digital"} exact>
          <FrameDigital />
        </Route>
        <Route path={baseRouteUrl + "/frame"} exact>
          <Frame />
        </Route>
        <Route path={baseRouteUrl + "/frame-photo"} exact>
          <FramePhoto />
        </Route>
        <Route path={baseRouteUrl + "/frame-canvas"} exact>
          <FrameCanvas />
        </Route>
        <Route path={baseRouteUrl + "/frame-textil"} exact>
          <FrameTextil />
        </Route>
        <Route path={baseRouteUrl + "/frame-object"} exact>
          <FrameObject />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo-pele-mele"} exact>
          <PeleMeleFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo-sur-mesure"} exact>
          <MadeToMeasurePhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo"} exact>
          <PhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-photo-personnalise"} exact>
          <CustomPhotoFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-sur-mesure"} exact>
          <MadeToMeasure />
        </Route>
        <Route path={baseRouteUrl + "/caisse-americaine"} exact>
          <CaisseAmericaine />
        </Route>
        <Route path={baseRouteUrl + "/cadre-bois"} exact>
          <WoodFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-dore"} exact>
          <GoldenFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-tableau"} exact>
          <TableauFrame />
        </Route>
        <Route path={baseRouteUrl + "/cadre-maillot"} exact>
          <JerseyFrame />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-paris"} exact>
          <FramerParis />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-lyon"} exact>
          <FramerLyon />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-bordeaux"} exact>
          <FramerBordeaux />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-marseille"} exact>
          <FramerMarseille />
        </Route>
        <Route path={baseRouteUrl + "/encadreur-nantes"} exact>
          <FramerNantes />
        </Route>
        <Route path={baseRouteUrl + "/legal"} exact>
          <Legal />
        </Route>
        <Route path={baseRouteUrl + "/trade"} exact>
          <Trade />
        </Route>
        <Route path={baseRouteUrl + "/faq"} exact>
          <FAQ />
        </Route>
        <Route path={baseRouteUrl + "/how-it-works"} exact>
          <HowItWorks />
        </Route>
        <Route path={baseRouteUrl + "/how-it-works-digital"} exact>
          <HowItWorksDigital />
        </Route>
        <Route path={baseRouteUrl + "/how-it-works-physical"} exact>
          <HowItWorksPhysical />
        </Route>
        <Route path={baseRouteUrl + "/our-mission"} exact>
          <OurMission />
        </Route>
        <Route path={baseRouteUrl + "/pricing"} exact>
          <Pricing />
        </Route>
        <Route path={baseRouteUrl + "/services"} exact>
          <Services />
        </Route>
        <Route path={baseRouteUrl + "/guide"} exact>
          <Guide />
        </Route>
        <Route path={baseRouteUrl + "/know-how"} exact>
          <KnowHow />
        </Route>
        <Route path={baseRouteUrl + "/build-digital"} exact>
          <BuildUpload />
        </Route>
        <Route path={baseRouteUrl + "/build-size"} exact>
          <BuildSize />
        </Route>
        <Route path={baseRouteUrl + "/gallery"} exact>
          <Gallery />
        </Route>
        <Route
          path="/shop/Parisian Cars"
          exact
          component={() => {
            window.location.href = "https://camillegabarra.lamafactory.com/";
            return null;
          }}
        />
        <Route
          path="/shop/Parisian Cars/:article"
          exact
          component={(props: { match: { params: { article: string } } }) => {
            window.location.href =
              "https://camillegabarra.lamafactory.com/shop/Parisian Cars/" +
              props.match.params.article;
            return null;
          }}
        />
        <Route path={baseRouteUrl + "/order/:orderId/collectionMethod"}>
          <CollectionMethod />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/login"}>
          <UserLogin />
        </Route>
        <Route exact path={baseRouteUrl + "/product/:productName"}>
          <ProductDetailsTech2 />
        </Route>
        <Route
          exact
          path={baseRouteUrl + "/product/:productName/:mouldingName"}
        >
          <ProductDetailsStandard />
        </Route>
        <Route
          exact
          path={baseRouteUrl + "/product/pelemele/:productName/:mouldingName"}
        >
          <ProductDetailsPelemele />
        </Route>
        <Route
          exact
          path={
            baseRouteUrl + "/product/gallery-wall/:productName/:mouldingName"
          }
        >
          <ProductDetailsGalleryWall />
        </Route>
        <Route
          exact
          path={baseRouteUrl + "/gallery-wall/:productName/:mouldingName"}
        >
          <GalleryWallConfigurator />
        </Route>

        <Route exact path={baseRouteUrl + "/gifts"}>
          <Gift />
        </Route>
        <Route exact path={baseRouteUrl + "/gift-card"}>
          <GiftCard />
        </Route>
        <Route exact path={baseRouteUrl + "/petit-lama"}>
          <SmallLama />
        </Route>
        <Route exact path={baseRouteUrl + "/pelemele"}>
          <Pelemele />
        </Route>
        <Route exact path={baseRouteUrl + "/gallery-walls"}>
          <GalleryWall />
        </Route>

        <Route exact path={baseRouteUrl + "/article/:id"}>
          <ProductDetailsArticle />
        </Route>
        <Route path={baseRouteUrl + "/cart"}>
          <Cart />
        </Route>
        <Route path={baseRouteUrl + "/wishlist/:wishlistId"}>
          <Wishlist />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/info"}>
          <UserInfo />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/shippingAddress"}>
          <ShippingAddress />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/billingAddress"}>
          <BillingAddress />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/signup"}>
          <UserSignup />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/shippingMethod"}>
          <ShippingMethod />
        </Route>
        <Route path={baseRouteUrl + "/order/:orderId/payment"}>
          <PaymentMethod />
        </Route>
        <Route path={baseRouteUrl + "/account/reset/:token"}>
          <Reset />
        </Route>
        <Route path={baseRouteUrl + "/account/orders"}>
          <Orders />
        </Route>
        <Route path={baseRouteUrl + "/account/login"}>
          <Login />
        </Route>
        <Route path={baseRouteUrl + "/admin/create-frame"}>
          <AdminCreateFrame />
        </Route>
        <Route path={baseRouteUrl + "/admin/customize-frame/:productName"}>
          <AdminCustomizeFrame />
        </Route>
        <Route path={baseRouteUrl + "/admin/reviews"}>
          <AdminReviews />
        </Route>
        <Route path={baseRouteUrl + "/authRedirection"}>
          <AuthRedirection />
        </Route>
        <Route path={baseRouteUrl + "/newsletter-unsubscribe/:token"}>
          <NewsletterUnsubscribe />
        </Route>
        <Route path={baseRouteUrl + "/register"}>
          <Register />
        </Route>
        <Route path={baseRouteUrl + "/feedback/:orderId"}>
          <Feedback />
        </Route>
        <Route path={baseRouteUrl + "/reviews"}>
          <Reviews />
        </Route>
        <Route path={baseRouteUrl + "/"}>
          <Home />
        </Route>
      </IonRouterOutlet>
    );
  }

  const appRef = createRef<HTMLIonAppElement>();
  useEffect(() => {
    if (isPlatform("mobile")) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }, []);

  const mutationObserver = new MutationObserver((mutationsList, observer) => {
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName === "class") {
        if ((mutation.target as any).className.includes("hydrated")) {
          const ele = document.getElementById("ipl-progress-indicator");
          if (ele) {
            ele.classList.add("available");
            ele.outerHTML = "";
          }
        }
      }
    });
  });

  useEffect(() => {
    if (appRef.current) {
      mutationObserver.observe(appRef.current, { attributeFilter: ["class"] });
    }
  }, []);

  return (
    <IonApp ref={appRef}>
      <IonReactRouter>
        <AuthContextProvider>
          <FContextProvider>
            <PriceContextProvider>
              <CContextProvider>
                <IntercomProvider autoBoot={true} appId={INTERCOM_APP_ID}>
                  <React.Suspense
                    fallback={
                      <IonLoading
                        //cssClass='my-custom-class'
                        isOpen={true}
                        message={"Chargement..."}
                      />
                    }
                  >
                    {/* <NewsletterSubscriptionModal /> */}
                    <SideDrawer />
                    <CookieModal />
                    <main>{routes}</main>
                  </React.Suspense>
                </IntercomProvider>
              </CContextProvider>
            </PriceContextProvider>
          </FContextProvider>
        </AuthContextProvider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
