import React from "react";

export interface cartItem {
  article_id: string;
  quantity: Number;
}

interface Context {
  cartItems: Array<cartItem> | null;
  addItem: (article_id: string, quantity: Number) => void;
  addItems: (articles: Array<{ article_id: string; quantity: Number }>) => void;
  removeItem: (article_id: string) => void;
  changeQuantity: (article_id: string, quantity: Number) => void;
  clearCart: () => void;
}

const cContext = React.createContext<Context>({
  cartItems: null,
  addItem: () => {},
  addItems: () => {},
  removeItem: () => {},
  changeQuantity: () => {},
  clearCart: () => {},
});

export default cContext;
